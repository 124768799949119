import React from "react"
import ChoiceQuiz from "../../../components/layouts/choice_quiz"
const data = require("../../../components/japanese/data/na_or_no.json")

const instruction = "Choose whether the adjective is more likely to use な or の";
const footer = <div>Confused? Check out <a href="https://www.tofugu.com/japanese/na-adjectives-no-adjectives/">this page!</a></div>

class NaVsNo extends React.Component  {
    handleClick = (answer) => {
      alert(answer);
    }
    render() {
        return (
          <ChoiceQuiz title="な vs の Adjectives" data={data} instruction={instruction} footer={footer}></ChoiceQuiz>
        );
      }
}

export default NaVsNo