import React from "react"
import PropTypes from "prop-types"
import * as styles from '../styles/quizmaster.module.scss'
import BackButton from "../backbutton"
import Question from "../quiz/question"
import CorrectScore from "../quiz/correct-score"
import MultipleChoice from "../quiz/multiple-choice"
import Feedback from "../quiz/feedback"
import DarkmodeButton from "../darkmode"
import UserFeedback from "../user-feedback"
import Typography from "typography"
import SEO from "../seo"
import bootstrapTheme from 'typography-theme-bootstrap'

bootstrapTheme.headerFontFamily = ['Open Sans', 'sans-serif']
bootstrapTheme.bodyFontFamily = ['Open Sans', 'sans-serif']
bootstrapTheme.includeNormalize = false;
bootstrapTheme.overrideThemeStyles = () => ({'body': {margin: 0}})

const typography = new Typography(bootstrapTheme);

const Furigana = require("gem-furigana").Furigana

class ChoiceQuiz extends React.Component  {
    constructor(props) {
        super(props);
        typography.injectStyles()
        this.correct = 0;
        this.incorrect = 0;
        this.answer = 0;
        this.wait = false;
        this.state = {
            question: "",
            answer: this.answer,
            correct: this.correct,
            incorrect: this.incorrect,
            wait: this.wait
        }
    }

    newQuestion = () => {
        let choice = this.props.data.choices[Math.floor(Math.random() * this.props.data.choices.length)]
        let item = this.props.data.questions[choice][Math.floor(Math.random() * this.props.data.questions[choice].length)]
        this.answer = choice
        this.setState({ question: item, answer: choice });
    }
    
    handleClick = (answer) => {
      var status = "";
      if (this.wait) {
        this.wait = false;
        this.newQuestion()
      } else {
        this.wait = true;
        if (answer === this.answer) {
            this.correct += 1;
            status = "correct";
        } else {
            this.incorrect += 1;
            status = "incorrect";
        }
      }
      this.setState({ correct: this.correct, incorrect: this.incorrect, status: status, wait: this.wait })
    }

    componentDidMount() {
        this.newQuestion();
    }


    render() {
        return (
          <>
            <SEO title={this.props.title} description={this.props.instruction} />
            <div className={styles.body}>
              <BackButton/>
              <div className={styles.content}>
                <h1 className={styles.heading} dangerouslySetInnerHTML={{ __html: this.props.title ? this.props.title : "Practice Page"}} />
                <Question kanji={this.props.kanji} furi ruby>
                  {new Furigana(this.state.question).ReadingHtml}
                </Question>
                <div style={{marginBottom:"20px", opacity: 0.5}}>{this.props.instruction}</div>
                <CorrectScore correct={this.state.correct} incorrect={this.state.incorrect} />
                <MultipleChoice choices={this.props.data.choices} answer={this.state.answer} showAnswer={this.state.wait} handleClick={this.handleClick}></MultipleChoice>
                {this.state.wait && <p style={{marginTop:"20px", opacity: 0.5}}>Press <strong>Enter ↵</strong> to continue...</p>}
                <br></br>
                <Feedback answer={this.state.answer} status={this.state.status} />
              </div>
              {this.props.footer}
              <div className={styles.buttons}>
                <DarkmodeButton />
                <UserFeedback/>
              </div>
            </div>
          </>
          
        );
      }
}

ChoiceQuiz.propTypes = {
    title: PropTypes.string,
    data: PropTypes.object.isRequired,
    kanji: PropTypes.bool,
    instruction: PropTypes.string,
    footer: PropTypes.node
}

export default ChoiceQuiz