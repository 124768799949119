import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const StyledMultipleChoice = styled.div`
  width: 300px;
  margin: auto;
  display: contents;
  button {
    font-size: 1.5em;
    color: var(--textNormal);
    background: transparent;
    border: 2px solid;
    border-color: var(--blue);
    border-radius: 6px; 
    padding: 0.2em 23px 0.4em 23px;
    :hover {
      background-color: var(--blue);
      color: white;
    }
    &.correct {
      border-color: var(--green);
      background-color: var(--green);
    }
    &.incorrect {
      border-color: var(--red);
      background-color: var(--red);
    }
    :focus {
      border-color: var(--blue);
    }
  }
  
  #top-button {
    margin: 0 100px 15px;
  }
  #left-button {
    margin-right: 100px;
  }
  #bottom-button {
    margin-top: 15px;
  }
`

const MultipleChoice = (props) => {
    const handleClick = e => {
        if(props.showAnswer) {document.activeElement.blur();}
        props.handleClick(e.target.innerText);
    }

    const handleKey = e => {
        if(props.showAnswer) {
          if(e.which === 13) {
            props.handleClick("");
            document.activeElement.blur();
          }
        } else {
          var button;
          if(e.which === 37) {button = document.getElementById("left-button")}
          if(e.which === 38) {button = document.getElementById("top-button")}
          if(e.which === 39) {button = document.getElementById("right-button")}
          if(e.which === 40) {button = document.getElementById("bottom-button")}
          if (button) {
            button.click();
            button.focus();
          }
        }
    }

    useEventListener('keydown', handleKey);

    return (
        <StyledMultipleChoice>
            {props.choices[2] && <button id="top-button" className={(props.showAnswer) ? ((props.answer === props.choices[2]) ? "correct":"incorrect"):""} onClick={handleClick}>{props.choices[2]}</button>}
            <button id="left-button" className={(props.showAnswer) ? ((props.answer === props.choices[0]) ? "correct":"incorrect"):""} onClick={handleClick}>{props.choices[0]}</button>
            <button id="right-button" className={(props.showAnswer) ? ((props.answer === props.choices[1]) ? "correct":"incorrect"):""} onClick={handleClick}>{props.choices[1]}</button>
            {props.choices[3] && <button id="bottom-button" className={(props.showAnswer) ? ((props.answer === props.choices[3]) ? "correct":"incorrect"):""} onClick={handleClick}>{props.choices[3]}</button>}
        </StyledMultipleChoice>
    );
};

function useEventListener(eventName, handler, element = (typeof window !== 'undefined') ? window : null){
    const savedHandler = useRef();
    useEffect(() => {
      savedHandler.current = handler;
    }, [handler]);
  
    useEffect(() => {
        const isSupported = element && element.addEventListener;
        if (!isSupported) return;
        const eventListener = event => savedHandler.current(event);
        element.addEventListener(eventName, eventListener);
        return () => {
          element.removeEventListener(eventName, eventListener);
        };
      },[eventName, element]
    );
  };

MultipleChoice.propTypes = {
    choices: PropTypes.array.isRequired,
    handleClick: PropTypes.func.isRequired,
    showAnswer: PropTypes.bool.isRequired
}

export default MultipleChoice;
